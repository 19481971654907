@tailwind base;

@tailwind components;

@tailwind utilities;

@layer components {
  .input-box {
    @apply px-2 py-1 outline-none border rounded;
  }
  .input-box:focus {
    @apply outline-none;
  }
  .modal-content {
    @apply bg-white px-6 py-4 border border-gray-300 shadow-lg rounded;
  }

  .button-small {
    @apply bg-white py-1 px-2 shadow rounded border border-gray-200 text-xs;
  }
  .button-small:focus {
    @apply outline-none;
  }
  .button-small:hover {
    @apply font-medium;
  }

  .button-active {
    @apply bg-gray-700 border-gray-700 text-white font-medium;
  }

  .button-popup {
    @apply py-1 w-full font-medium text-gray-700;
  }
  .button-popup:focus {
    @apply outline-none;
  }
  .button-popup:hover {
    @apply bg-gray-200;
  }

  .card {
    @apply border border-gray-300 rounded p-4 bg-white shadow w-full;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-spinners input[type="number"] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.firebase-emulator-warning {
  opacity: 0.5;
}

/* PRINT STYLES: */
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print-full-w {
    max-width: 100%;
    width: 100%;
  }
  .print-center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  .print-bg-white {
    background-color: #fff !important;
  }
  .print-no-padding {
    padding: 0 !important;
  }
  .print-hide-shadow  {
    box-shadow: none !important;
  }
  .print-show-all {
    height: auto !important;
  }
  .print-avoid-breaks {
    page-break-inside: avoid;
  }
  .print-break-before {
    page-break-before: always;
  }
}

/* SCROLLBAR STYLES: */

/* width */
.scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: rgb(34, 34, 34);
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 6px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(82, 82, 82);
}

/* width */
.scrollbar-light::-webkit-scrollbar {
  width: 8px;
}

/* Track */
/* .scrollbar-light::-webkit-scrollbar-track {
} */

/* Handle */
.scrollbar-light::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 6px;
}

/* Handle on hover */
.scrollbar-light::-webkit-scrollbar-thumb:hover {
  background: #9c9c9c;
}

/* TRANSITION STYLES */
.transition-width-100ms {
  -webkit-transition: width 0.1s; /* Safari prior 6.1 */
  transition: width 0.1s;
}

.highlighted-card-inactive::after {
  opacity: 0;
}
.highlighted-card-active::after {
  opacity: 1;
}

.transition-highlighted-card::after {
  box-shadow: 0 5px 15px rgba(77, 190, 158, 0.527);
  content: " ";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.transition-highlighted-card  {
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
